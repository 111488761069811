import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from 'react-query';
import Icon from '@components/Icons/Icon';
import ModalIconBanner from "@components/Modals/components/ModalIconBanner";
import ModalContent from '@components/Modals/components/ModalContent';
import { makeStyles } from 'tss-react/mui';
import ConnectGameButton from '@components/AddGame/ConnectGameButton';
import connectFacebookAccount from '../helpers/connectFacebookAccount';
const useStyles = makeStyles()((theme) => ({
    facebookLogo: {
        height: '85px !important',
        fill: theme.palette.facebook.main,
    },
}));
/**
 * This is one of the steps in the add social media account modal flow
 *
 * @param props The component props
 * @param props.onSuccess Called when the Facebook account is successfully connected
 * @param props.onError Called when there is a problem connecting the account
 * @returns The component
 */
const ConnectFacebookAccount = ({ onSuccess, onError }) => {
    const { classes } = useStyles();
    const { isLoading, mutate } = useMutation(connectFacebookAccount, { onSuccess, onError });
    return (_jsxs(ModalContent, { stepName: "connect-facebook", align: "center", children: [_jsx(ModalIconBanner, { children: _jsx(Icon, { icon: "facebook", className: classes.facebookLogo }) }), _jsxs("h2", { children: [_jsx("span", { "data-ignore": true, children: "Facebook" }), "\u00A0Login"] }), _jsx("section", { children: _jsxs("p", { children: ["You'll be redirected to the\u00A0", _jsx("span", { "data-ignore": true, children: "Facebook" }), "\u00A0Login page in order to complete this process."] }) }), _jsx(ConnectGameButton, { isLoading: isLoading, paletteName: "facebook", onClick: () => mutate(), children: _jsxs("span", { "data-ignore": true, children: ["Login to Your ", _jsx("span", { "data-ignore": true, children: "Facebook" }), isLoading] }) })] }));
};
export default ConnectFacebookAccount;
