import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from '@mui/system';
import Icon from '@components/Icons/Icon';
import { Choice } from '@components/UI/Choice';
import getSocialMediaServiceNameFromType from '@helpers/SocialMedia/getSocialMediaServiceNameFromType';
const SocialButtonIcon = styled(Icon) `
  fill: ${({ theme, paletteName }) => theme.palette[paletteName].main};
`;
const Container = styled('div') `
  align-self: stretch;
  & > span {
    color: ${({ theme }) => theme.palette.grey[900]};
  }
`;
const StyledChoice = styled(Choice) `
  margin: ${({ theme }) => theme.spacing(3)} 0;
`;
const ConnectSocialMediaAccounts = ({ availableTypes, onSelect }) => {
    const socialMediaAccountOptions = availableTypes.map((type) => ({
        id: type,
        value: type,
        label: getSocialMediaServiceNameFromType(type),
        icon: _jsx(SocialButtonIcon, { icon: type, paletteName: type }),
        paletteName: type,
        type,
    }));
    const handleSelect = (selected) => {
        if (onSelect) {
            onSelect(selected);
        }
    };
    return (_jsx(Container, { children: _jsx(StyledChoice, { choices: socialMediaAccountOptions, onSelect: handleSelect, selectButtonLabel: "Connect Account", resolveChoiceMode: (choiceCount) => {
                if (choiceCount > 9) {
                    return 'select';
                }
                if (choiceCount > 4) {
                    return 'grid';
                }
                return 'buttons';
            } }) }));
};
export default ConnectSocialMediaAccounts;
