import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import getGamePalette from '@helpers/getGamePalette';
import getGameName from '@helpers/getGameName';
import ModalBody from '@components/Modals/components/ModalBody';
import ModalBreadcrumb from '@components/Modals/components/ModalBreadcrumb';
import ModalHeader from '@components/Modals/components/ModalHeader';
import GameIcon from '@components/Icons/GameIcon';
import useAddGameBreadcrumbManager from '@hooks/useAddGameBreadcrumbManager';
import useRemoveAddGameParams from '@hooks/useRemoveAddGameParams';
import { setConnectGameServiceAction, setConnectPlatformAction, setConnectRegionAction, } from '../context/AddGameStepsActions';
import ContentCarousel from '../../UI/ContentCarousel';
import { GameAgeConsent } from '../../Modals/AddGameModal/Components/GameAgeConsent';
import ContentCODConnect from './ContentCODConnect';
import ContentCODLoginServices from './ContentCODLoginServices';
import ContentCODRegions from './ContentCODRegions';
import { useAddGameContext } from '../context/AddGameProvider';
import AddGameConnectStatus from '../AddGameConnectStatus';
import AddGameInterstitial from '../AddGameInterstitial';
import AddGameOAuthConnectContent from '../AddGameOAuthConnectContent';
import getAnalyticsFlowStep from '@domain/Analytics/helpers/getAnalyticsFlowStep';
const gameStepBreadcrumbs = [
    {
        id: 'game-service',
        component: 'Service',
        analyticsFlowStep: 'select game platform',
    },
    {
        id: 'region',
        component: 'Region',
        analyticsFlowStep: 'select account region',
    },
    {
        id: 'oauth-connect',
        component: 'Connect',
        analyticsFlowStep: 'connect game',
    },
    {
        id: 'username-connect',
        component: 'Connect',
        analyticsFlowStep: 'enter game information',
    },
];
/**
 * A ModalBody for adding COD-Warzone
 * @param onStepChange - invoked whenever the steps changes. Returns the step index and current step name if available.
 * @param onConnectConfirm - invoked when user clicks "Confirm" in the connect success screen.
 * @param shouldGoToConnectionStatus - boolean to determine whether this view should be displaying connection status view.
 */
export const AddCODWarzone = ({ onStepChange, onConnectConfirm, shouldShowSelectBreadcrumb = true, onBackToSelect, shouldGoToConnectionStatus = false, successButtonLabel = 'Continue', failButtonLabel = 'Retry', onClose, }) => {
    const gameKey = 'cod-warzone';
    const removeAddGameParams = useRemoveAddGameParams();
    // Breadcrumbs have more items than the steps. i.e. Select > Profile. This offset is used for breadcrumb/step relationship when navigating between steps
    const breadcrumbStepOffset = shouldShowSelectBreadcrumb ? 1 : 0;
    // Used to determine whether the Modal is ready to render. Currently only used for age check
    const [currentStepIndex, setCurrentStepIndex] = useState(shouldGoToConnectionStatus ? -1 : 0);
    const { requireAgePrompt, dispatch, gameConnectionStatus, isProviderReady, fetched, connectGameService } = useAddGameContext();
    // NOTE: Age check for breadcrumb is managed by the hook below.
    const { breadcrumbIndex, breadcrumbs, breadcrumbIsVisible, setBreadcrumbIsVisible, displayBreadcrumbsUpTo, getIndexOfBreadcrumb, } = useAddGameBreadcrumbManager({
        gameStepBreadcrumbs,
        startingIndex: breadcrumbStepOffset,
        includeSelectBreadcrumb: shouldShowSelectBreadcrumb,
        requireAgePrompt,
    });
    const getStepIndexFromBreadcrumbId = (relatedBreadcrumbName) => getIndexOfBreadcrumb(relatedBreadcrumbName) - breadcrumbStepOffset;
    const onRegionSelected = (region, service) => {
        dispatch(setConnectRegionAction(region));
        // Based on the selected game service, go to a different connect step
        const targetStepName = !!service.oauth ? 'oauth-connect' : 'username-connect';
        const newStepIndex = getStepIndexFromBreadcrumbId(targetStepName);
        setCurrentStepIndex(newStepIndex);
    };
    const updateDefaultPlatformAndRegionFromService = (service) => {
        var _a;
        // COD usually only has one platform
        if ((service === null || service === void 0 ? void 0 : service.platforms.length) === 1) {
            const selectedPlatform = service.platforms[0];
            dispatch(setConnectPlatformAction(selectedPlatform));
            // If there is only a single region, select it automatically
            if (((_a = selectedPlatform.regions) === null || _a === void 0 ? void 0 : _a.length) === 1) {
                onRegionSelected(selectedPlatform.regions[0], service);
            }
        }
    };
    const onLoginServiceSelected = (service) => {
        dispatch(setConnectGameServiceAction(service));
        setCurrentStepIndex(currentStepIndex + 1);
        updateDefaultPlatformAndRegionFromService(service);
    };
    const handleBreadcrumbClick = (idx, breadcrumb) => {
        if (breadcrumb.id === 'select') {
            // Invoke onBackToSelect to dismiss current view
            if (onBackToSelect) {
                onBackToSelect(gameKey);
            }
        }
        else if (idx >= breadcrumbStepOffset) {
            // Breadcrumb contains "Select" as first step thus pushing all index forward by 1
            setCurrentStepIndex(idx - breadcrumbStepOffset);
        }
    };
    const handleStepChange = (changedStepIndex, stepName) => {
        if (stepName === 'game-connect-status') {
            setBreadcrumbIsVisible(false);
        }
        else if (stepName === 'username-connect') {
            setBreadcrumbIsVisible(true);
            displayBreadcrumbsUpTo('username-connect', ['region', 'oauth-connect']);
        }
        else {
            setBreadcrumbIsVisible(true);
            displayBreadcrumbsUpTo(stepName);
        }
        if (onStepChange) {
            const analyticsFlowStep = getAnalyticsFlowStep(gameConnectionStatus, changedStepIndex, gameStepBreadcrumbs);
            onStepChange(changedStepIndex, stepName, analyticsFlowStep, gameStepBreadcrumbs.length);
        }
    };
    const handleContinue = () => {
        setCurrentStepIndex(currentStepIndex + 1);
    };
    const handleConnectSuccessClick = () => {
        if (onConnectConfirm) {
            onConnectConfirm();
        }
    };
    const handleConnectFailClick = () => {
        removeAddGameParams();
        if (connectGameService === null || connectGameService === void 0 ? void 0 : connectGameService.oauth) {
            if (connectGameService.platforms.flatMap((p) => p.regions).length > 1) {
                setCurrentStepIndex(getStepIndexFromBreadcrumbId('region'));
            }
            else {
                setCurrentStepIndex(getStepIndexFromBreadcrumbId('oauth-connect'));
            }
        }
        else {
            setCurrentStepIndex(getStepIndexFromBreadcrumbId('username-connect'));
        }
    };
    const handleClose = () => {
        const isSuccessPage = breadcrumbIndex === -1;
        if (onClose) {
            onClose(gameConnectionStatus === 'success', isSuccessPage);
        }
    };
    const onUsernameSubmit = () => {
        setCurrentStepIndex(currentStepIndex + 1);
    };
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { title: _jsx("span", { "data-ignore": true, children: getGameName(gameKey) }), icon: _jsx(GameIcon, { game: gameKey, showGameColor: true }), subtitle: "Connect your account", paletteName: getGamePalette(gameKey), onClose: handleClose }), _jsx(ModalBreadcrumb, { currentIndex: breadcrumbIndex, breadcrumbs: breadcrumbs, onClick: handleBreadcrumbClick, isVisible: breadcrumbIsVisible }), _jsxs(ModalBody, { children: [isProviderReady && (_jsxs(ContentCarousel, { name: "cod-warzone", currentStep: currentStepIndex, onStepChange: handleStepChange, children: [requireAgePrompt && _jsx(GameAgeConsent, { stepName: "age", onContinue: handleContinue }), _jsx(ContentCODLoginServices, { stepName: "game-service", onLoginServiceSelected: onLoginServiceSelected }), _jsx(ContentCODRegions, { stepName: "region", onRegionSelected: onRegionSelected }), _jsx(AddGameOAuthConnectContent, { stepName: "oauth-connect" }), _jsx(ContentCODConnect, { stepName: "username-connect", onSubmit: onUsernameSubmit }), _jsx(AddGameConnectStatus, { onConfirm: handleConnectSuccessClick, onRetry: handleConnectFailClick, status: gameConnectionStatus || 'error', gameKey: gameKey, successButtonLabel: successButtonLabel, failButtonLabel: failButtonLabel, errorMessage: (fetched === null || fetched === void 0 ? void 0 : fetched.isError) ? fetched.errorMessage : undefined, isLoading: !connectGameService, stepName: "game-connect-status" })] })), !isProviderReady && _jsx(AddGameInterstitial, {})] })] }));
};
export default AddCODWarzone;
