import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import ModalContent from "@components/Modals/components/ModalContent";
import toRealMoney from '@helpers/toRealMoney';
import LoadingLogo from '@components/Modals/LoadingModal/Components/LoadingLogo';
import useAppState from '@hooks/API/useAppState';
import useGetBalance from "@components/Hooks/useGetBalance";
import CoreButton from "@components/Buttons/CoreButton";
import Coins from '@components/Icons/Coins';
import Dollar from '@components/Icons/Dollar';
import completePaypalDeposit from '@api/payment/completePaypalDeposit';
import beginPaypalDeposit from '@api/payment/beginPaypalDeposit';
import getPaymentStatusFromOrderState from '@components/Modals/helpers/getPaymentStatusFromOrderState';
import { PayPalButtonWrapper } from './PayPalButtonWrapper';
import { captureMessage, Severity } from '@sentry/react';
const useStyles = makeStyles()((theme) => ({
    creditCard: {
        fill: '#FF5F00',
        height: '35px',
    },
    finePrint: {
        textAlign: 'center',
        fontSize: '12px',
        color: theme.palette.grey[500],
    },
    hidden: {
        display: 'none',
    },
    loading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '200px',
    },
    paymentButtons: {
        '& button': {
            fontWeight: 600,
        },
    },
    transferButton: {
        width: '100%',
        marginBottom: '12px',
        display: 'flex',
        justifyContent: 'space-between',
        height: '35px',
        padding: 0,
        fontWeight: 600,
        color: theme.palette.grey[900],
        '&:hover': {
            color: theme.palette.grey[900],
        },
    },
    transferButtonIconLeft: {
        height: '35px',
        width: '35px',
        padding: theme.spacing(1),
        '& svg': {
            maxHeight: '100%',
            maxWidth: '100%',
            width: 'auto',
            height: '100%',
        },
        borderRight: `1px solid ${theme.palette.grey[200]}`,
    },
    transferButtonIconRight: {
        height: '35px',
        width: '35px',
        padding: theme.spacing(1),
        '& svg': {
            maxHeight: '100%',
            maxWidth: '100%',
            width: 'auto',
            height: '100%',
        },
        borderLeft: `1px solid ${theme.palette.grey[200]}`,
    },
}));
const PaymentMethod = ({ onSuccess, onError, paypalOrderId, transactionAmount, currency, currencyAmount, setPaypalOrderId, setPaymentStatus, setError, paymentStatus, onBalanceTransfer, }) => {
    const { classes, cx } = useStyles();
    const [displayFinePrint, setDisplayFinePrint] = useState(false);
    const { refetch: refetchBalance } = useGetBalance();
    const { paymentMethods, isLoading, error } = useAppState();
    if (error || (!isLoading && !paymentMethods)) {
        return (_jsx(ModalContent, { align: "center", children: _jsxs("div", { className: classes.loading, children: [_jsx(LoadingLogo, {}), _jsx("p", { children: "An error has occurred, please refresh the page and try again" })] }) }));
    }
    if (isLoading || !paymentMethods) {
        return (_jsx(ModalContent, { align: "center", children: _jsxs("div", { className: classes.loading, children: [_jsx(LoadingLogo, {}), _jsx("p", { children: "Processing" })] }) }));
    }
    const { clientId, fixedFee, percentFee } = paymentMethods.paypal;
    const percentageFee = percentFee * 0.01;
    const minAmount = currency === 'PM' ? 5 : 10;
    const handleError = async () => {
        setPaymentStatus('ERROR');
        if (paypalOrderId) {
            try {
                await completePaypalDeposit({
                    paypalOrderId,
                    newOrderState: 'error',
                });
            }
            catch {
                // This is a best effort attempt to update the Order with the error state
            }
        }
        setPaypalOrderId(undefined);
        onError();
    };
    const handleMinAmountNotMet = () => {
        setError({
            errorMessage: `The minimum amount for this transaction is $${toRealMoney(minAmount)} USD`,
            allowRetry: true,
        });
        return false;
    };
    const createOrder = async (data, actions) => {
        if (!transactionAmount) {
            setPaymentStatus('ERROR');
            setError({ errorMessage: 'Please provide an amount for this transaction', allowRetry: true });
            return false;
        }
        const value = (transactionAmount + fixedFee) / (1.0 - percentageFee);
        if (value < minAmount) {
            return handleMinAmountNotMet();
        }
        const newPaypalOrderId = await actions.order.create({
            purchase_units: [
                {
                    amount: {
                        currency_code: 'USD',
                        value: ((transactionAmount + fixedFee) / (1.0 - percentageFee)).toFixed(2),
                    },
                },
            ],
            application_context: {
                shipping_preference: 'NO_SHIPPING', // default is "GET_FROM_FILE"
            },
        });
        if (!newPaypalOrderId) {
            throw new Error('Paypal create order did not return a new order ID');
        }
        setPaypalOrderId(newPaypalOrderId);
        const { orderState } = await beginPaypalDeposit({
            transactionType: currency === 'USD' ? 'deposit' : 'purchase',
            amount: transactionAmount,
            coins: currency === 'PM' ? currencyAmount : null,
            paypalOrderId: newPaypalOrderId,
        });
        setPaymentStatus(getPaymentStatusFromOrderState(orderState));
        return newPaypalOrderId;
    };
    const onCancel = async (data) => {
        setPaymentStatus('WAITING');
        if (data === null || data === void 0 ? void 0 : data.orderID) {
            // This is a best effort attempt to update the Order with the cancelled state - kick it off and ignore any exceptions
            completePaypalDeposit({
                paypalOrderId: data.orderID,
                newOrderState: 'cancelled',
            });
        }
    };
    const handleSuccess = async (data) => {
        setPaymentStatus('VALIDATING');
        if (!(data === null || data === void 0 ? void 0 : data.id)) {
            captureMessage('Successful paypal order but no order ID', Severity.Critical);
            setError({
                errorMessage: 'Something went wrong after your payment was taken. Please contact support immediately.',
                allowRetry: false,
            });
            handleError();
            return;
        }
        let completePaypalDepositResponse;
        try {
            completePaypalDepositResponse = await completePaypalDeposit({
                paypalOrderId: data.id,
                newOrderState: 'completed',
                transaction: data,
            });
        }
        catch (err) {
            setError({
                errorMessage: 'Something went wrong after your payment was taken. Please contact support immediately.',
                allowRetry: false,
            });
            handleError();
            return;
        }
        setPaymentStatus(getPaymentStatusFromOrderState(completePaypalDepositResponse.orderState));
        refetchBalance();
        onSuccess();
    };
    const onPaypalButtonReady = () => {
        setDisplayFinePrint(true);
    };
    return (_jsxs(ModalContent, { align: "center", children: [paymentStatus === 'VALIDATING' && (_jsxs("div", { className: classes.loading, children: [_jsx(LoadingLogo, {}), _jsx("p", { children: "Processing Payment" })] })), _jsxs("div", { className: cx(paymentStatus === 'VALIDATING' && classes.hidden, classes.paymentButtons), children: [currency === 'PM' && (_jsxs(CoreButton, { variant: "grey", appearance: "outline", size: "sm", className: classes.transferButton, onClick: onBalanceTransfer, children: [_jsx("div", { className: classes.transferButtonIconLeft, children: _jsx(Dollar, {}) }), "Convert Cash to Coins", _jsx("div", { className: classes.transferButtonIconRight, children: _jsx(Coins, {}) })] })), _jsx(PayPalButtonWrapper, { options: {
                            clientId,
                        }, onError: handleError, createOrder: createOrder, onSuccess: handleSuccess, onCancel: onCancel, onButtonReady: onPaypalButtonReady })] }), displayFinePrint && (_jsxs("p", { className: classes.finePrint, children: ["Deposit Fee: ", fixedFee.toLocaleString(undefined, { style: 'currency', currency: 'USD' }), " +", ' ', percentageFee.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1 }), " of the transaction amount"] }))] }));
};
export default PaymentMethod;
