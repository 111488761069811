import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { styled, css } from '@mui/material/styles';
import classnames from 'classnames';
import Currency from '@components/UI/Currency';
import { getTournamentEndsInDisplay, getTournamentRegistrationStatus, getTournamentState, } from '@domain/Tournament/helpers/tournamentUtils';
import TournamentMetric from '@domain/Tournament/components/TournamentMetric';
import useAppState from '@hooks/API/useAppState';
const TournamentContainer = styled('div') `
  font-family: ${(props) => props.theme.typography.fontFamily};
  display: flex;
  min-height: 80px;
  grid-template-columns: 80px auto 245px 145px 180px;
  position: relative;
  overflow: hidden;
  border-left: 1px solid ${(props) => props.theme.palette.grey[100]};
  justify-content: flex-end;
  padding-right: ${(props) => (props.align === 'center' ? '0px' : '20px')};

  &.entered {
    grid-template-columns: 80px auto 225px 180px;
  }

  & .hover {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: ${(props) => props.theme.palette.success.main};
    transform: translateX(100%);
    transition: transform 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: ${(props) => props.theme.palette.common.white};

    & p {
      margin-bottom: 0px;
      font-size: 12px;
    }

    & p:first-of-type {
      font-size: 16px;
      font-weight: 600;
    }

    &.primary {
      background: ${(props) => props.theme.palette.primary.main};
    }
    &.coins {
      background: ${(props) => props.theme.palette.orange.main};
    }
  }

  &:hover .hover {
    transform: translateX(0%);
  }
`;
const StyledCurrencyContainer = styled('div') `
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  font-family: ${(props) => props.theme.typography.fontFamily};
  height: 25px;
`;
const ViewTournament = styled(TournamentMetric) `
  text-align: right;
  margin: 0;
  border: 0;
  flex: 1;
  align-items: ${(props) => (props.align === 'center' ? 'center' : 'flex-end')};
  align-self: stretch;
  justify-content: center;

  & > div:first-of-type,
  > dd {
    display: flex;
    font-weight: 600;
    font-size: 16px;
    color: ${(props) => props.theme.palette.grey[900]};
    margin-bottom: 4px;
  }
  & span,
  dt {
    margin-right: 5px;
  }

  ${(props) => props.freeEntry &&
    css `
      & > div:first-of-type {
        color: ${props.theme.palette.primary.main};
      }
    `}
`;
const ViewText = styled('p') `
  color: ${(props) => props.theme.palette.success.main};
  font-size: 16px;
  font-weight: 600;
  margin: 0;

  ${(props) => props.tournamentState === 'full' &&
    css `
      color: ${props.theme.palette.secondary.main};
    `}
  ${(props) => props.tournamentState === 'finished' ||
    (props.tournamentState === 'closed' &&
        css `
        color: ${props.theme.palette.error.main};
      `)}
`;
export const TournamentEntryFee = ({ tournament, align, className, withDollarSymbol, }) => {
    const appState = useAppState();
    const tournamentState = getTournamentState(tournament, appState.registrationEndingMinutes);
    const registrationStatus = getTournamentRegistrationStatus(tournament, appState.registrationEndingMinutes);
    const canEnter = registrationStatus === 'open' && !tournament.user_entry;
    const tournamentEntryStatus = tournamentState !== 'started' && tournamentState !== 'open' ? 'closed' : 'open';
    const hasEntered = tournament.user_entry;
    const entryCaption = tournamentEntryStatus === 'open' ? (_jsx("span", { "data-ignore": true, children: `Closes in ${getTournamentEndsInDisplay(tournament)}` })) : (`Entries are ${tournamentState.charAt(0).toUpperCase()}${tournamentState.slice(1)}`);
    const tournamentCurrency = tournament.currencyType.abbreviation;
    const hoverStyleClass = tournamentCurrency === 'USD' ? 'primary' : 'coins';
    return (_jsxs(TournamentContainer, { align: align, className: className, "cy-testid": "view tournament", children: [_jsxs(ViewTournament, { caption: entryCaption, align: align, freeEntry: tournament.entry_fee === 0, children: [canEnter && tournamentEntryStatus === 'closed' && (_jsx(ViewText, { tournamentState: tournamentState, children: tournamentState.charAt(0).toUpperCase() + tournamentState.slice(1) })), canEnter && tournamentEntryStatus === 'open' && (_jsxs(_Fragment, { children: [tournament.entry_fee === 0 && 'Free ', tournament.entry_fee !== 0 && (_jsx(StyledCurrencyContainer, { children: _jsx(Currency, { withDollarSymbol: withDollarSymbol, optimise: true, amount: tournament.entry_fee, currency: tournamentCurrency }) })), "Entry"] })), hasEntered && _jsx(ViewText, { children: "View" }), !hasEntered && tournamentEntryStatus === 'closed' && (_jsx(ViewText, { children: tournamentState.charAt(0).toUpperCase() + tournamentState.slice(1) }))] }), _jsxs("div", { className: classnames(canEnter && hoverStyleClass, 'hover'), children: [_jsxs("p", { children: [canEnter ? 'Join' : 'View', " Now ", _jsx("i", { className: "fas fa-caret-right" })] }), _jsx("p", { children: entryCaption })] })] }));
};
export default TournamentEntryFee;
