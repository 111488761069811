import { FacebookAuthProvider, OAuthProvider, getAuth, linkWithPopup } from 'firebase/auth';
import claimIdentityProvider from '@api/claimIdentityProvider';
import isFirebaseAuthError from '@helpers/Auth/isFirebaseAuthError';
const connectFacebookAccount = async () => {
    var _a, _b;
    const auth = getAuth();
    try {
        if (!auth.currentUser) {
            throw new Error('Must be logged in');
        }
        const result = await linkWithPopup(auth.currentUser, new FacebookAuthProvider());
        if (!((_a = result.user) === null || _a === void 0 ? void 0 : _a.email)) {
            throw new Error('User data was missing');
        }
        // success!
    }
    catch (error) {
        // If credential is already in use, attempt to claim it
        if (isFirebaseAuthError(error) && error.code === 'auth/credential-already-in-use') {
            const credential = OAuthProvider.credentialFromError(error);
            if (!(credential === null || credential === void 0 ? void 0 : credential.accessToken)) {
                throw new Error('Access token was missing!');
            }
            await claimIdentityProvider(credential.providerId, credential.accessToken);
            await ((_b = auth.currentUser) === null || _b === void 0 ? void 0 : _b.reload());
            // success!
        }
        else {
            throw error;
        }
    }
};
export default connectFacebookAccount;
