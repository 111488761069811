import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { styled } from '@mui/system';
import { Divider } from '@mui/material';
import { useUserState } from '@context/UserContext';
import { useLocalAppContext } from '@context/LocalAppContext';
import useRemoveWrapperPadding from "@hooks/useRemoveWrapperPadding";
import CoreButton from "@components/Buttons/CoreButton";
import ErrorFillIcon from "@components/Icons/ErrorFillIcon";
const EmailConfirmationErrorContainer = styled('div') `
  display: flex;
`;
const ErrorContent = styled('div') `
  max-width: 440px;
  width: 33.3333333%;
  margin: 10vh auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(7)};
  background-color: ${({ theme }) => theme.palette.common.white};
  & > img {
    width: 135px;
  }
  & > h1 {
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    color: ${({ theme }) => theme.palette.grey[900]};
    margin-top: ${({ theme }) => theme.spacing(5)};
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
  & > p {
    font-weight: 600;
    font-size: 12px;
    color: ${({ theme }) => theme.palette.grey[300]};
    text-align: center;
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 100%;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
    padding: ${({ theme }) => theme.spacing(2)};
  } ;
`;
const StyledCoreButton = styled(CoreButton) `
  font-size: 12px;
  font-weight: 700;
  margin-top: ${({ theme }) => theme.spacing(2)};
`;
const StyledText = styled('p') `
  color: ${({ theme }) => theme.palette.grey[500]};

  &.strong {
    color: ${({ theme }) => theme.palette.grey[600]};
  }
  &.error {
    color: ${({ theme }) => theme.palette.error.main};
  }
`;
const StyledDivider = styled(Divider) `
  margin: ${({ theme }) => theme.spacing(3, 0)};
  width: 100%;
`;
const StyledErrorIcon = styled(ErrorFillIcon) `
  width: 95px;
`;
const EmailConfirmationError = ({ errorMessage, errorCode, onLoginSuccess }) => {
    const { authenticated } = useUserState();
    useRemoveWrapperPadding();
    const { openModal } = useLocalAppContext();
    const handleLoginButton = () => {
        openModal({
            type: 'login',
            onSuccess: () => {
                onLoginSuccess();
            },
        });
    };
    return (_jsx(EmailConfirmationErrorContainer, { children: _jsxs(ErrorContent, { children: [_jsx(StyledErrorIcon, {}), _jsx("h1", { children: "Unable to verify email" }), errorMessage && _jsx(StyledText, { className: "error", children: errorMessage }), _jsxs(StyledText, { children: ["Try again or contact", ' ', _jsx("span", { "data-ignore": true, children: _jsx("strong", { children: _jsx("a", { href: "https://support.repeat.gg/hc/en-us/requests/new", rel: "noopener noreferrer", target: "_blank", children: "contact support" }) }) }), ' ', errorCode && ' with the error code', "."] }), errorCode && (_jsxs(_Fragment, { children: [_jsx(StyledDivider, {}), _jsx(StyledText, { children: "Error Code" }), _jsx(StyledText, { className: "error", children: errorCode })] })), !authenticated && (_jsx(_Fragment, { children: _jsx(StyledCoreButton, { block: true, onClick: handleLoginButton, children: "Log in" }) })), authenticated && (_jsx(StyledCoreButton, { block: true, to: "/compete", children: "Start Competing" }))] }) }));
};
export default EmailConfirmationError;
