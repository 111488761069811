import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import CaretDown from '@components/Icons/CaretDown';
const useStyles = makeStyles()((theme, _params, classes) => ({
    slideImage: { width: '88px' },
    accordionChevron: {
        width: '13px',
        fill: theme.palette.grey[200],
    },
    accordionRoot: {
        borderBottom: `1px ${theme.palette.grey[100]} solid`,
        boxShadow: 'none',
        minHeight: '48px',
        '&:last-child': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        [`&.${classes.accordionRootExpanded}`]: {
            margin: 0,
        },
    },
    accordionRootExpanded: {},
    accordionSummaryRoot: {
        border: 'none',
        color: theme.palette.grey[900],
        fontWeight: 600,
        fontSize: '12px',
        fontFamily: theme.typography.fontFamily,
        minHeight: '0px',
        // paddingLeft: 0,
        [`&.${classes.expandedAccordionSummaryContent}`]: {
            margin: 0,
            height: '46px',
            minHeight: '48px',
        },
    },
    accordionSummaryContent: {
        margin: 0,
        height: '46px',
        minHeight: '48px',
        alignItems: 'center',
        '& h4': {
            margin: 0,
            fontSize: '12px',
            color: theme.palette.grey[900],
        },
    },
    expandedAccordionSummaryContent: {
        marginTop: '0px !important',
        marginBottom: '0px !important',
    },
    accordionDetails: {
        fontSize: '10px',
        fontWeight: 500,
        color: theme.palette.grey[400],
        lineHeight: '14px',
        paddingTop: 0,
        paddingBottom: theme.spacing(0.5),
    },
}));
const AccordionPanel = ({ heading, content, id, contentId, defaultExpanded = false, className }) => {
    const { classes, cx } = useStyles();
    return (_jsxs(Accordion, { defaultExpanded: defaultExpanded, classes: {
            root: cx(classes.accordionRoot, className),
            expanded: classes.accordionRootExpanded,
        }, children: [_jsx(AccordionSummary, { expandIcon: _jsx(CaretDown, { className: classes.accordionChevron, title: `Toggle ${heading}` }), "aria-controls": contentId, id: id, classes: {
                    root: classes.accordionSummaryRoot,
                    content: classes.accordionSummaryContent,
                    expanded: classes.expandedAccordionSummaryContent,
                }, children: _jsx("h4", { children: heading }) }), _jsx(AccordionDetails, { className: classes.accordionDetails, children: content })] }));
};
export default AccordionPanel;
