import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { AppBar } from '@mui/material';
import { Link } from 'react-router-dom';
import { Feature } from 'flagged';
import { styled } from '@mui/material/styles';
import CoreButton from "@components/Buttons/CoreButton";
import HamburgerMenu from "@components/Icons/HamburgerMenu";
import RepeatLogo from '@components/Icons/Brand/RepeatLogo';
import { useUserState } from '@context/UserContext';
import { useLocalAppContext } from '@context/LocalAppContext';
import ChevronDown from '@components/Icons/ChevronDown';
import useSetPageHeaderStyle from '@hooks/useSetPageHeaderStyle';
import NotificationMenu from '@domain/Notification/NotificationMenu';
import useRouteChanged from '@hooks/useRouteChanged';
import MobileNavigationMenu from './Menus/MobileNavigationMenu';
const MobileHeader = styled(AppBar) `
  font-family: ${({ theme }) => theme.typography.fontFamily};
  display: flex;
  flex-direction: row;
  height: 72px;
  justify-content: space-between;
`;
const LogoContainer = styled(Link) `
  background: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  flex-shrink: 0;
  padding: ${({ theme }) => theme.spacing(2)};

  & > svg {
    fill: white;
    width: 100%;
  }
`;
const JoinNowButtonContainer = styled('div') `
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(3)}`};
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[100]};
  border-top: 1px solid ${({ theme }) => theme.palette.grey[100]};

  & > button {
    width: 100%;
  }

  @media (max-width: 320px) {
    padding: ${({ theme }) => `${theme.spacing(2)}`};
  }
`;
const MobileMenuButton = styled(CoreButton) `
  width: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: 1px solid ${({ theme }) => theme.palette.grey[100]}!important;
  border-right: none;

  & > svg {
    fill: ${({ theme }) => theme.palette.grey[400]};
    width: 20px;
  }
`;
const ProfileButtonContainer = styled('div') `
  width: 100%;
  display: flex;
  justify-content: flex-end;

  & > button {
    border-left: 1px solid ${({ theme }) => theme.palette.grey[100]};
    padding: 0 ${({ theme }) => theme.spacing(2)};
  }
`;
const ProfileLogo = styled('div') `
  width: 30px;
  height: 30px;
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  border: 2px solid ${({ theme }) => theme.palette.grey[100]};
  margin-right: ${({ theme }) => theme.spacing(1)};

  & > img {
    width: 100%;
  }
`;
const ProfileChevron = styled(ChevronDown) `
  fill: ${({ theme }) => theme.palette.grey[200]};
  height: 8px;
`;
/**
 * New Mobile Navigation Header when the new header feature flag is on.
 * The mobile header should only be used for screen breakpoint below
 * 1280px within the AppBar
 */
const MobileNavigationHeader = ({ onShowLoginModal, onShowRegisterModal, onShowWithdrawalModal, onShowFundsModal, onShowCouponModal, }) => {
    const { authenticated, userProfile } = useUserState();
    const [showMenu, setShowMenu] = useState(false);
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const { openModal } = useLocalAppContext();
    const handleJoinNow = () => {
        onShowRegisterModal();
    };
    const handleOpen = () => {
        setShowMenu(true);
    };
    const handleOpenProfileMenu = () => {
        setShowMenu(true);
        setShowProfileMenu(true);
    };
    const handleClose = () => {
        setShowMenu(false);
        setShowProfileMenu(false);
    };
    // Close the menu when a user clicks a link
    useRouteChanged(() => {
        handleClose();
    });
    useSetPageHeaderStyle(72);
    const handleShowAddGameModal = () => {
        handleClose();
        openModal({ type: 'add-game', onClose: () => { } });
    };
    return (_jsxs(MobileHeader, { position: "fixed", color: "inherit", children: [_jsx(LogoContainer, { to: "/", children: _jsx(RepeatLogo, { className: "logo", title: "Repeat.GG" }) }), !authenticated && (_jsx(JoinNowButtonContainer, { children: _jsxs(CoreButton, { type: "button", onClick: handleJoinNow, roundCorners: false, children: ["Join\u00A0", _jsx("span", { "data-ignore": true, children: "Repeat" }), "\u00A0Now"] }) })), authenticated && (_jsxs(ProfileButtonContainer, { children: [_jsx(Feature, { name: "enable_notifications", children: _jsx(NotificationMenu, {}) }), _jsxs(CoreButton, { type: "button", appearance: "text", roundCorners: false, onClick: handleOpenProfileMenu, ariaLabel: "Profile Menu", children: [_jsx(ProfileLogo, { children: _jsx("img", { src: userProfile === null || userProfile === void 0 ? void 0 : userProfile.profileTinyUrl, alt: "player icon" }) }), _jsx(ProfileChevron, {})] })] })), _jsx(MobileMenuButton, { type: "button", appearance: "text", roundCorners: false, onClick: handleOpen, children: _jsx(HamburgerMenu, { title: "Open menu" }) }), _jsx(MobileNavigationMenu, { onShowFundsModal: onShowFundsModal, onShowWithdrawalModal: onShowWithdrawalModal, onShowCouponModal: onShowCouponModal, loginAction: onShowLoginModal, open: showMenu, openProfileMenu: showProfileMenu, onClose: handleClose })] }));
};
export default MobileNavigationHeader;
