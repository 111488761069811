import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import GameIcon from '@components/Icons/GameIcon';
import GameAgeConsent from '@components/Modals/AddGameModal/Components/GameAgeConsent';
import ContentCarousel from '@components/UI/ContentCarousel';
import getGamePalette from '@helpers/getGamePalette';
import getGameName from '@helpers/getGameName';
import useAddGameBreadcrumbManager from '@hooks/useAddGameBreadcrumbManager';
import { useState } from 'react';
import { useAddGameContext } from '../context/AddGameProvider';
import { setConnectRegionAction } from '../context/AddGameStepsActions';
import AddGameConnectStatus from '../AddGameConnectStatus';
import AddGameInterstitial from '../AddGameInterstitial';
import useSetDefaultGameService from '../hooks/useSetDefaultGameService';
import ContentConnectValorant from './ContentConnectValorant';
import ContentValorantRegions from './ContentValorantRegions';
import getAnalyticsFlowStep from '@domain/Analytics/helpers/getAnalyticsFlowStep';
import ModalBody from "@components/Modals/components/ModalBody";
import ModalBreadcrumb from "@components/Modals/components/ModalBreadcrumb";
import ModalHeader from "@components/Modals/components/ModalHeader";
const gameKey = 'valorant';
const gameStepBreadcrumbs = [
    {
        id: 'region',
        component: 'Region',
        analyticsFlowStep: 'select account region',
    },
    {
        id: 'connect-valorant',
        component: 'Connect',
        analyticsFlowStep: 'connect game',
    },
];
const AddValorant = ({ onStepChange, onConnectConfirm, shouldShowSelectBreadcrumb = true, onBackToSelect, successButtonLabel = 'Continue', failButtonLabel = 'Retry', onClose, }) => {
    const breadcrumbStepOffset = shouldShowSelectBreadcrumb ? 1 : 0;
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const { requireAgePrompt, availableGameServices, gameConnectionStatus, dispatch, isProviderReady, fetched } = useAddGameContext();
    const { breadcrumbIndex, breadcrumbs, breadcrumbIsVisible, setBreadcrumbIsVisible, displayBreadcrumbsUpTo } = useAddGameBreadcrumbManager({
        gameStepBreadcrumbs,
        startingIndex: breadcrumbStepOffset,
        includeSelectBreadcrumb: shouldShowSelectBreadcrumb,
        requireAgePrompt,
    });
    useSetDefaultGameService();
    const handleBreadcrumbClick = (idx, breadcrumb) => {
        if (breadcrumb.id === 'select') {
            // Invoke onBackToSelect to dismiss current view
            if (onBackToSelect) {
                onBackToSelect(gameKey);
            }
        }
        else if (idx >= breadcrumbStepOffset) {
            setCurrentStepIndex(idx - breadcrumbStepOffset);
        }
    };
    const handleStepChange = (changedStepIndex, stepName) => {
        if (stepName === 'game-connect-status') {
            setBreadcrumbIsVisible(false);
        }
        else {
            setBreadcrumbIsVisible(true);
            displayBreadcrumbsUpTo(stepName);
        }
        if (onStepChange) {
            const analyticsFlowStep = getAnalyticsFlowStep(gameConnectionStatus, changedStepIndex, gameStepBreadcrumbs);
            onStepChange(changedStepIndex, stepName, analyticsFlowStep, gameStepBreadcrumbs.length);
        }
    };
    const handleContinue = () => {
        setCurrentStepIndex(currentStepIndex + 1);
    };
    const handleConnectSuccessClick = () => {
        if (onConnectConfirm) {
            onConnectConfirm();
        }
    };
    const handleConnectRetryClick = () => {
        setCurrentStepIndex(-2); // Second last step
    };
    const handleClose = () => {
        const isSuccessPage = breadcrumbIndex === -1;
        if (onClose) {
            onClose(gameConnectionStatus === 'success', isSuccessPage);
        }
    };
    const handleRegionSelect = (region) => {
        dispatch(setConnectRegionAction(region));
        handleContinue();
    };
    const handleValorantAccountSubmit = () => {
        setCurrentStepIndex(currentStepIndex + 1);
    };
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { title: _jsx("span", { "data-ignore": true, children: getGameName(gameKey) }), icon: _jsx(GameIcon, { game: gameKey, showGameColor: true }), subtitle: "Connect your account", paletteName: getGamePalette(gameKey), onClose: handleClose }), _jsx(ModalBreadcrumb, { currentIndex: breadcrumbIndex, breadcrumbs: breadcrumbs, onClick: handleBreadcrumbClick, isVisible: breadcrumbIsVisible }), _jsxs(ModalBody, { children: [isProviderReady && (_jsxs(ContentCarousel, { name: "Valorant", currentStep: currentStepIndex, onStepChange: handleStepChange, children: [requireAgePrompt && _jsx(GameAgeConsent, { stepName: "age", onContinue: handleContinue }), _jsx(ContentValorantRegions, { onRegionSelected: handleRegionSelect, stepName: "region" }), _jsx(ContentConnectValorant, { stepName: "connect-valorant", onSubmit: handleValorantAccountSubmit }), _jsx(AddGameConnectStatus, { onConfirm: handleConnectSuccessClick, onRetry: handleConnectRetryClick, status: gameConnectionStatus || 'error', gameKey: gameKey, successButtonLabel: successButtonLabel, failButtonLabel: failButtonLabel, errorMessage: (fetched === null || fetched === void 0 ? void 0 : fetched.isError) ? fetched.errorMessage : undefined, stepName: "game-connect-status" })] })), !isProviderReady && _jsx(AddGameInterstitial, {})] })] }));
};
export default AddValorant;
