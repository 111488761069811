import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import ChevronLeft from '@components/Icons/ChevronLeft';
import ChevronRight from '@components/Icons/ChevronRight';
import CoreButton from "@components/Buttons/CoreButton";
import { SROnly } from '@components/UI';
const useStyles = makeStyles()((theme) => ({
    carouselArrowsWrapper: {
        display: 'flex',
        '& > button': {
            marginRight: theme.spacing(1),
        },
        '& > button:last-child': {
            marginRight: '0px',
        },
    },
    arrow: {
        background: '#fff',
        width: '40px',
        height: '40px',
        padding: '0px',
        '&:active': {
            background: theme.palette.primary.main,
            '& svg': {
                fill: theme.palette.common.white,
            },
        },
        '&:hover': {
            borderColor: theme.palette.primary.main,
            '& svg': {
                fill: theme.palette.primary.main,
            },
        },
        '& svg': {
            fill: theme.palette.grey[300],
            width: '8px',
        },
    },
}));
const useShouldDisableNextButton = (currentPage, slidesPerPage, slideCount) => useMemo(() => Math.ceil(currentPage / slidesPerPage) + 1 >= Math.ceil(slideCount / slidesPerPage), [
    currentPage,
    slideCount,
    slidesPerPage,
]);
const CarouselArrows = ({ currentPage, slidesPerPage, slideCount, onChange }) => {
    const { classes } = useStyles();
    const disableNextButton = useShouldDisableNextButton(currentPage, slidesPerPage, slideCount);
    const handlePrevClick = () => onChange(currentPage - 1);
    const handleNextClick = () => onChange(currentPage + 1);
    return (_jsxs("div", { className: classes.carouselArrowsWrapper, children: [_jsxs(CoreButton, { variant: "grey", className: classes.arrow, onClick: handlePrevClick, disabled: currentPage === 0, children: [_jsx(ChevronLeft, {}), _jsx(SROnly, { children: "Previous Slide" })] }), _jsxs(CoreButton, { variant: "grey", className: classes.arrow, onClick: handleNextClick, disabled: disableNextButton, children: [_jsx(ChevronRight, {}), _jsx(SROnly, { children: "Next Slide" })] })] }));
};
export default CarouselArrows;
