import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from 'react';
import { Tabs, Tab } from '@mui/material';
import classnames from 'classnames';
import Icon from '@components/Icons/Icon';
import { NavLink, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
const AppBar = styled('div') `
  border-top: 0px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  padding: ${({ theme }) => theme.spacing(0, 2.5)};

  &:before {
    display: block;
    content: '';
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 2px;
    background: ${({ theme }) => theme.palette.grey[200]};
    z-index: 0;
  }

  @media (max-width: 739px) {
    border-left: none;
    border-right: none;
  }
`;
const StyledTabs = styled(Tabs) `
  /* https://styled-components.com/docs/basics#pseudoelements-pseudoselectors-and-nesting precedence boost */
  && {
    border-bottom: 0px !important;

    .indicator {
      background-color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`;
const Fader = styled('div') `
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  top: 0;
  pointer-events: none;
  position: absolute;
  width: 30px;
  z-index: 100;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: none;
  }

  &.faderLeft {
    background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    left: 0;
  }

  &.faderRight {
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    right: 0;
  }
`;
const StyledIcon = styled(Icon) `
  max-height: 16px;
  opacity: 0.25;
  width: 30px;
`;
// Had to hack/combine the merged component types? https://mui.com/material-ui/guides/routing/#tabs
const TabBehavior = React.forwardRef((props, ref) => _jsx(Tab, { ref: ref, ...props }));
const StyledTab = styled(TabBehavior) `
  && {
    font-size: 14px;
    text-transform: none;
    min-width: 130px;
    min-height: 67px;
    font-weight: 600;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: ${({ theme }) => theme.palette.grey[600]};

    &:hover {
      color: ${({ theme }) => theme.palette.grey[900]};
      opacity: 1;
    }

    &.selectedTab {
      font-weight: bold;
      color: ${({ theme }) => theme.palette.primary.main};

      &:link,
      &:focus {
        color: ${({ theme }) => theme.palette.primary.main};
      }
    }
  }
`;
const TabNavigator = ({ tabData, onTabSelected, className }) => {
    const { pathname, hash } = useLocation();
    const selectedTabIndex = tabData.findIndex((tab) => {
        return `${pathname}${hash}` === `${tab.route}`;
    });
    return (_jsx(AppBar, { className: classnames(className), children: _jsx(StyledTabs, { classes: { indicator: 'indicator' }, value: selectedTabIndex >= 0 ? selectedTabIndex : 0, onChange: (_, value) => onTabSelected === null || onTabSelected === void 0 ? void 0 : onTabSelected(value), "aria-label": "navigation tabs", "data-testid": "navigation tabs", variant: "scrollable", scrollButtons: "auto", ScrollButtonComponent: ({ direction, disabled }) => disabled ? (_jsx(_Fragment, {})) : (_jsx(Fader, { className: direction === 'left' ? 'faderLeft' : 'faderRight', children: _jsx(StyledIcon, { icon: direction === 'left' ? 'chevronleft' : 'chevronright' }) })), children: tabData.map((tab) => (_jsx(StyledTab, { to: tab.route, component: NavLink, classes: { selected: 'selectedTab' }, disableRipple: true, label: tab.label, id: `tab-${tab.route}` }, `tab-${tab.route}`))) }) }));
};
export default TabNavigator;
