import getTournamentPlatformsDisplay from './getTournamentPlatformsDisplay';
import getTournamentModesDisplay from './getTournamentModesDisplay';
import getTournamentRegionsDisplay from './getTournamentRegionsDisplay';
import getTournamentServersDisplay from './getTournamentServersDisplay';
import getTournamentEntryTypeDisplay from './getTournamentEntryTypeDisplay';
import getGamePlatformTags from './getGamePlatformTags';
const getTournamentPlatformTags = (tournament, gameData) => {
    if ((gameData === null || gameData === void 0 ? void 0 : gameData.gameKey) === 'fortnite' || tournament.score_template.game.game_key === 'fortnite') {
        return getTournamentPlatformsDisplay(tournament);
    }
    if (gameData) {
        return getGamePlatformTags(gameData);
    }
    return [{ label: 'All Platforms' }];
};
const getTournamentTags = (tournament, gameData, includePlatforms = false) => {
    const results = [
        { label: 'Leaderboard' },
        { icon: 'mappin', label: getTournamentRegionsDisplay(tournament) },
        { icon: 'server', label: getTournamentServersDisplay(tournament, gameData) },
    ];
    const entryType = getTournamentEntryTypeDisplay(tournament);
    if (entryType) {
        results.unshift(entryType);
    }
    // Add the featured tag to the beginning of the TagData
    if (tournament.featured) {
        results.unshift({
            label: 'Featured',
            palette: 'secondary',
        });
    }
    const modesDisplay = getTournamentModesDisplay(tournament);
    if (modesDisplay) {
        results.push({ icon: 'trophy', label: modesDisplay });
    }
    if (includePlatforms) {
        results.push(...getTournamentPlatformTags(tournament, gameData));
    }
    return results;
};
export default getTournamentTags;
