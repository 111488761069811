import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import getGamePalette from '@helpers/getGamePalette';
import getGameName from '@helpers/getGameName';
import useAddGameBreadcrumbManager from '@hooks/useAddGameBreadcrumbManager';
import ModalBody from '@components/Modals/components/ModalBody';
import ModalBreadcrumb from '@components/Modals/components/ModalBreadcrumb';
import ModalHeader from '@components/Modals/components/ModalHeader';
import GameIcon from '@components/Icons/GameIcon';
import ContentCarousel from '../../UI/ContentCarousel';
import { GameAgeConsent } from '../../Modals/AddGameModal/Components/GameAgeConsent';
import { setConnectGameServiceAction, setConnectPlatformAction, setConnectRegionAction, } from '../context/AddGameStepsActions';
import { useAddGameContext } from '../context/AddGameProvider';
import AddGameConnectStatus from '../AddGameConnectStatus';
import AddGameInterstitial from '../AddGameInterstitial';
import AddGameOAuthConnectContent from '../AddGameOAuthConnectContent';
import useRemoveAddGameParams from '@hooks/useRemoveAddGameParams';
import getAnalyticsFlowStep from '@domain/Analytics/helpers/getAnalyticsFlowStep';
const AddDestiny2 = ({ onStepChange, onConnectConfirm, shouldShowSelectBreadcrumb = true, onBackToSelect, successButtonLabel = 'Continue', failButtonLabel = 'Retry', shouldGoToConnectionStatus = false, onClose, }) => {
    const gameKey = 'destiny-2';
    const gameStepBreadcrumbs = [
        {
            id: 'oauth-connect',
            component: 'Connect',
            analyticsFlowStep: 'connect game',
        },
    ];
    const removeAddGameParams = useRemoveAddGameParams();
    const breadcrumbStepOffset = shouldShowSelectBreadcrumb ? 1 : 0;
    const [currentStepIndex, setCurrentStepIndex] = useState(shouldGoToConnectionStatus ? -1 : 0);
    const { requireAgePrompt, dispatch, availableGameServices, isProviderReady, gameConnectionStatus, fetched } = useAddGameContext();
    // NOTE: Age check for breadcrumb is managed by the hook below.
    const { breadcrumbIndex, breadcrumbs, breadcrumbIsVisible, setBreadcrumbIsVisible, displayBreadcrumbsUpTo, getIndexOfBreadcrumb, } = useAddGameBreadcrumbManager({
        gameStepBreadcrumbs,
        startingIndex: breadcrumbStepOffset,
        includeSelectBreadcrumb: shouldShowSelectBreadcrumb,
        requireAgePrompt,
    });
    const handleBreadcrumbClick = (idx, breadcrumb) => {
        if (breadcrumb.id === 'select') {
            // Invoke onBackToSelect to dismiss current view
            if (onBackToSelect) {
                onBackToSelect(gameKey);
            }
        }
        else if (idx >= breadcrumbStepOffset) {
            setCurrentStepIndex(idx - breadcrumbStepOffset);
        }
    };
    const handleStepChange = (changedStepIndex, stepName) => {
        if (stepName === 'game-connect-status') {
            setBreadcrumbIsVisible(false);
        }
        else {
            setBreadcrumbIsVisible(true);
            displayBreadcrumbsUpTo(stepName);
        }
        if (onStepChange) {
            const analyticsFlowStep = getAnalyticsFlowStep(gameConnectionStatus, changedStepIndex, gameStepBreadcrumbs);
            onStepChange(changedStepIndex, stepName, analyticsFlowStep, gameStepBreadcrumbs.length);
        }
    };
    const handleContinue = () => {
        setCurrentStepIndex(currentStepIndex + 1);
    };
    const handleConnectSuccessClick = () => {
        if (onConnectConfirm) {
            onConnectConfirm();
        }
    };
    const getStepIndexFromBreadcrumbId = (relatedBreadcrumbName) => getIndexOfBreadcrumb(relatedBreadcrumbName) - breadcrumbStepOffset;
    const handleConnectFailClick = () => {
        removeAddGameParams();
        setCurrentStepIndex(getStepIndexFromBreadcrumbId('oauth-connect'));
    };
    const handleClose = () => {
        const isSuccessPage = breadcrumbIndex === -1;
        if (onClose) {
            onClose(gameConnectionStatus === 'success', isSuccessPage);
        }
    };
    const handleSubmit = () => {
        setCurrentStepIndex(currentStepIndex + 1);
    };
    // Add default game service and regions (only 1)
    // Logic moved to individual add games from add game provider, as it was confusing for developers.
    useEffect(() => {
        if (availableGameServices) {
            // Destiny 2 is a bit different to the other games - it has a game service for connecting the account ('bungie'),
            // and other game services for modelling the user's various 'memberships'. Our data structures don't model this
            // well - for now, just 'know' that we need to use 'bungie' for connecting.
            const bungieGameService = availableGameServices.gameServices.find((gameService) => gameService.gameServiceKey === 'bungie');
            const defaultPlatform = bungieGameService === null || bungieGameService === void 0 ? void 0 : bungieGameService.platforms[0];
            const defaultRegion = defaultPlatform === null || defaultPlatform === void 0 ? void 0 : defaultPlatform.regions[0];
            if (bungieGameService) {
                dispatch(setConnectGameServiceAction(bungieGameService));
            }
            if (defaultPlatform) {
                dispatch(setConnectPlatformAction(defaultPlatform));
            }
            if (defaultRegion) {
                dispatch(setConnectRegionAction(defaultRegion));
            }
        }
    }, [availableGameServices]);
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { title: _jsx("span", { "data-ignore": true, children: getGameName(gameKey) }), icon: _jsx(GameIcon, { game: gameKey, showGameColor: true }), subtitle: "Connect your account", paletteName: getGamePalette(gameKey), onClose: handleClose }), _jsx(ModalBreadcrumb, { currentIndex: breadcrumbIndex, breadcrumbs: breadcrumbs, onClick: handleBreadcrumbClick, isVisible: breadcrumbIsVisible }), _jsxs(ModalBody, { children: [isProviderReady && (_jsxs(ContentCarousel, { name: "destiny-2", currentStep: currentStepIndex, onStepChange: handleStepChange, children: [requireAgePrompt && _jsx(GameAgeConsent, { stepName: "age", onContinue: handleContinue }), _jsx(AddGameOAuthConnectContent, { stepName: "oauth-connect" }), _jsx(AddGameConnectStatus, { onConfirm: handleConnectSuccessClick, onRetry: handleConnectFailClick, status: gameConnectionStatus || 'error', gameKey: gameKey, successButtonLabel: successButtonLabel, failButtonLabel: failButtonLabel, errorMessage: (fetched === null || fetched === void 0 ? void 0 : fetched.isError) ? fetched.errorMessage : undefined, stepName: "game-connect-status" })] })), !isProviderReady && _jsx(AddGameInterstitial, {})] })] }));
};
export default AddDestiny2;
