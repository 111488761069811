import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from '@mui/system';
import BaseDeleteIcon from '@components/Icons/DeleteIcon';
import isMultiColorIcon from '@helpers/isMultiColorIcon';
import getSocialMediaServiceNameFromType from '@helpers/SocialMedia/getSocialMediaServiceNameFromType';
import getSocialMediaAccountDisplayName from '@helpers/SocialMedia/getSocialMediaAccountDisplayName';
import LinkedAccountRow from '../../components/LinkedAccountRow';
const DeleteIcon = styled(BaseDeleteIcon) `
  width: 11px;
  height: 14px;
  fill: ${({ theme }) => theme.palette.grey[400]};
`;
const Tooltip = styled('span') `
  font-size: 10px;
`;
const SocialMediaAccountItem = ({ socialMediaAccount, handleDeleteAccount }) => {
    const iconColor = isMultiColorIcon(socialMediaAccount.type) ? undefined : socialMediaAccount.type;
    const handleActionButtonClick = () => handleDeleteAccount(socialMediaAccount);
    return (_jsx(LinkedAccountRow, { text: getSocialMediaServiceNameFromType(socialMediaAccount.type), subtext: getSocialMediaAccountDisplayName(socialMediaAccount), tooltip: _jsx(Tooltip, { "data-notranslate": true, children: getSocialMediaAccountDisplayName(socialMediaAccount) }), icon: socialMediaAccount.type, iconColor: iconColor, actionButtonContent: _jsx(DeleteIcon, {}), onActionButtonPress: handleActionButtonClick }, socialMediaAccount.type));
};
export default SocialMediaAccountItem;
