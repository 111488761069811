import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@components/Icons/Icon';
import { css, styled } from '@mui/material/styles';
const shouldForwardProp = (prop) => prop !== 'paletteName' && prop !== 'choiceMode' && prop !== 'noIcon' && prop !== 'choiceId';
const SelectButton = styled('button', { shouldForwardProp }) `
  background: transparent;
  border: none;
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  display: flex;
  align-items: stretch;
  padding: 0;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  box-sizing: border-box;
  margin: ${({ theme }) => theme.spacing(1, 0)};
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.grey[900]};
  min-height: 50px;

  & > span {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: ${({ theme }) => theme.spacing(2)};
    border: 1px solid ${({ theme }) => theme.palette.grey[200]};
    border-left: none;
    border-radius: 0 ${({ theme }) => theme.shape.borderRadius}px ${({ theme }) => theme.shape.borderRadius}px 0;
    align-items: center;
  }

  &:hover span {
    background: ${({ paletteName, theme }) => theme.palette[paletteName].main};
    color: white;
    border-radius: 0 ${({ theme }) => theme.shape.borderRadius}px ${({ theme }) => theme.shape.borderRadius}px 0;
  }

  &:hover .ChoiceSelectButton__chevron {
    fill: white;
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:hover span {
    color: ${({ theme }) => theme.palette.grey[300]};
    background: transparent;
    cursor: not-allowed;
  }

  &:disabled svg,
  &:disabled svg g,
  &:disabled svg g path,
  &:disabled:hover svg {
    fill: ${({ theme }) => theme.palette.grey[300]};
  }

  ${(props) => props.choiceMode === 'grid' &&
    css `
      height: 80px;
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      color: ${props.theme.palette.grey[900]};
      padding: ${props.theme.spacing(1, 2)};

      & svg {
        height: 20px;
      }
    `}

  ${(props) => props.noIcon &&
    css `
      border: 1px solid ${props.theme.palette.grey[200]};
      border-radius: ${props.theme.shape.borderRadius};
      display: flex;

      & span {
        border: none;
      }

      &:hover {
        background: ${props.theme.palette[props.paletteName].main};
        color: white;
      }

      &:hover span {
        background: transparent;
      }
    `}
`;
const SelectButtonIconContainer = styled('div') `
  display: flex;
  min-width: 50px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
  border-radius: ${(props) => props.theme.shape.borderRadius}px 0 0 ${(props) => props.theme.shape.borderRadius}px;

  & > svg {
    width: 24px;
    height: auto;
  }
`;
const SelectButtonChevron = styled(Icon) `
  fill: ${(props) => props.theme.palette.grey[200]};
  height: 12px;
  width: auto;
`;
const ChoiceSelectButton = ({ choiceMode, option, onSelect, disabled = false, }) => {
    var _a;
    return (_jsxs(SelectButton, { paletteName: (_a = option.paletteName) !== null && _a !== void 0 ? _a : 'primary', type: "button", "data-testid": choiceMode === 'grid' ? 'grid button' : 'button', choiceMode: choiceMode, noIcon: !(option === null || option === void 0 ? void 0 : option.icon), onClick: onSelect && (() => onSelect(option.value)), disabled: disabled, children: [(option === null || option === void 0 ? void 0 : option.icon) && _jsx(SelectButtonIconContainer, { children: option.icon }), _jsxs("span", { children: [option.label, _jsx(SelectButtonChevron, { icon: "chevronright", className: "ChoiceSelectButton__chevron" })] })] }, option.id));
};
export default ChoiceSelectButton;
