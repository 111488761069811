import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from '@mui/material';
import { Link } from 'react-router-dom';
import shouldForwardProp from '@emotion/is-prop-valid';
import GameIcon from '@components/Icons/GameIcon';
import useGame from '@hooks/API/useGame';
import { getTournamentColorPaletteName, getTournamentState } from '@domain/Tournament/helpers/tournamentUtils';
import getTournamentTags from '@domain/Tournament/helpers/getTournamentTags';
import TournamentProgressBar from '@domain/Tournament/components/TournamentProgressBar';
import TournamentMetric from '@domain/Tournament/components/TournamentMetric';
import TagList from '@components/UI/TagList';
import TournamentEntryFee from './TournamentEntryFee';
import TournamentAwardRankCell from './TournamentAwardRankCell';
import TournamentEligibility from './TournamentEligibility';
import { doTrackEvent } from '@components/Analytics';
import { extractTournamentAnalyticsData } from '@components/Analytics/doTrackEvent';
import useTournamentDetailsPageUrl from '@hooks/useTournamentDetailsPageUrl';
import withTournamentV2 from '../../domain/TournamentV2/withTournamentV2';
import TournamentRowV2 from '@domain/TournamentV2/TournamentTable/TournamentRow';
import useAppState from '@hooks/API/useAppState';
const TournamentContainer = styled(Link) `
  font-family: ${(props) => props.theme.typography.fontFamily};
  display: grid;
  box-shadow: 0px -1px 0px #eee inset;
  min-height: 80px;
  grid-template-columns: 80px auto 329px 155px 180px;
`;
const TournamentHeading = styled('div', { shouldForwardProp }) `
  display: flex;
  align-items: center;
  margin: 12px 0;
  padding: ${(props) => props.theme.spacing(0, 2)};
  color: ${(props) => props.theme.palette.grey[900]};

  &:hover {
    color: ${(props) => props.theme.palette[props.paletteName].main};
  }

  & p {
    font-size: 16px;
    font-weight: 600;
    margin: ${(props) => props.theme.spacing(0.5, 0)};
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
`;
const GameLogo = styled('div', { shouldForwardProp }) `
  align-items: center;
  align-self: stretch;
  justify-content: center;
  background-color: ${(props) => props.theme.palette[props.paletteName].light};
  display: flex;
  width: 80px;
  padding: ${({ theme }) => theme.spacing(1)};

  img {
    max-height: 40px;
    max-width: 40px;
  }
`;
const ProgressBarContainer = styled(TournamentMetric) `
  justify-content: center;
  border-left: 1px solid ${(props) => props.theme.palette.grey[100]};
  & > div {
    width: 100%;
  }
`;
const BrandIcon = styled('img') `
  height: 40px;
  min-width: 40px;
  border-radius: ${(props) => props.theme.shape.borderRadius};
  margin-right: ${(props) => props.theme.spacing(1)};
`;
const StyledTournamentAwardRankCell = styled(TournamentAwardRankCell) `
  justify-content: center;
  border-left: 1px solid ${(props) => props.theme.palette.grey[100]};
`;
const StyledTournamentEligibility = styled(TournamentEligibility) `
  justify-content: center;
  border-left: 1px solid ${(props) => props.theme.palette.grey[100]};
`;
const TournamentRow = ({ tournament, section, listIndex }) => {
    var _a, _b, _c, _d;
    const paletteName = getTournamentColorPaletteName(tournament);
    const appState = useAppState();
    const tournamentState = getTournamentState(tournament, appState.registrationEndingMinutes);
    const gameData = useGame(tournament.score_template.game.game_key);
    const detailsLink = useTournamentDetailsPageUrl(tournament.id, gameData);
    const onRowClick = () => {
        doTrackEvent({
            ...extractTournamentAnalyticsData(tournament, appState.registrationEndingMinutes),
            type: 'open_tournament',
            section,
            listIndex: listIndex.toString(),
            viewType: 'list',
        });
    };
    // Tournament registration is active and not entered
    const hasEntered = tournament.user_entry != undefined;
    return (_jsxs(TournamentContainer, { to: detailsLink, onClick: onRowClick, "data-testid": "tournament row", children: [_jsx(GameLogo, { paletteName: paletteName, children: _jsx(GameIcon, { optimise: true, game: tournament.score_template.game.game_key }) }), _jsxs(TournamentHeading, { paletteName: paletteName, children: [((_a = tournament === null || tournament === void 0 ? void 0 : tournament.brand) === null || _a === void 0 ? void 0 : _a.logo) && (_jsx(BrandIcon, { "data-notranslate": true, src: (_b = tournament.brand) === null || _b === void 0 ? void 0 : _b.logo, alt: `${(_d = (_c = tournament === null || tournament === void 0 ? void 0 : tournament.brand) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : `Brand logo of ${tournament.name}`}` })), _jsxs("div", { children: [_jsx("p", { "data-notranslate": true, children: tournament.name }), _jsx(TagList, { tags: getTournamentTags(tournament, gameData, true) })] })] }), hasEntered ? (_jsx(StyledTournamentEligibility, { tournament: tournament, align: "left" })) : (_jsx(ProgressBarContainer, { children: _jsx(TournamentProgressBar, { currentEntries: tournament.entries_count, maxEntries: tournament.slots, tournamentState: tournamentState, maxPrizePositions: tournament.maximum_prize_positions }) })), _jsx(StyledTournamentAwardRankCell, { tournament: tournament, align: "left" }), _jsx(TournamentEntryFee, { align: "right", tournament: tournament })] }));
};
export default withTournamentV2(TournamentRow, TournamentRowV2);
