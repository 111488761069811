import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import getGameName from '@helpers/getGameName';
import getGamePalette from '@helpers/getGamePalette';
import LoginServiceIcon from '@components/UI/Icon/LoginServiceIcon';
import ModalBody from "@components/Modals/components/ModalBody";
import ModalBreadcrumb from "@components/Modals/components/ModalBreadcrumb";
import ModalContent from "@components/Modals/components/ModalContent";
import ModalHeader from "@components/Modals/components/ModalHeader";
import GameIcon from '@components/Icons/GameIcon';
import { Choice } from '../../UI/Choice';
import { setConnectGameServiceAction, setConnectPlatformAction, setConnectRegionAction, } from '../context/AddGameStepsActions';
import { useAddGameContext } from '../context/AddGameProvider';
import AddGameConnectStatus from '../AddGameConnectStatus';
import AddGameInterstitial from '../AddGameInterstitial';
import AddGameOAuthConnectContent from '../AddGameOAuthConnectContent';
import useRemoveAddGameParams from '@hooks/useRemoveAddGameParams';
import LoadingAnimationPlaceholder from '@components/UI/LoadingAnimationPlaceholder';
import ContentCarousel from '@components/UI/ContentCarousel';
import useAddGameBreadcrumbManager from '@hooks/useAddGameBreadcrumbManager';
import getAnalyticsFlowStep from '@domain/Analytics/helpers/getAnalyticsFlowStep';
import useTrackEvent from '@hooks/useTrackEvent';
const gameStepBreadcrumbs = [
    {
        id: 'game-service',
        component: 'Service',
        analyticsFlowStep: 'select game platform',
    },
    {
        id: 'oauth-connect',
        component: 'Connect',
        analyticsFlowStep: 'connect game',
    },
];
const AddMLBShow23 = ({ onStepChange, onConnectConfirm, shouldShowSelectBreadcrumb = true, onBackToSelect, successButtonLabel = 'Continue', failButtonLabel = 'Retry', onClose, shouldGoToConnectionStatus, }) => {
    const breadcrumbStepOffset = shouldShowSelectBreadcrumb ? 1 : 0;
    const [currentStepIndex, setCurrentStepIndex] = useState(shouldGoToConnectionStatus ? -1 : 0);
    const { requireAgePrompt, dispatch, availableGameServices, isProviderReady, gameConnectionStatus, fetched } = useAddGameContext();
    const trackEvent = useTrackEvent();
    // NOTE: Age check for breadcrumb is managed by the hook below.
    const { breadcrumbIndex, breadcrumbs, breadcrumbIsVisible, setBreadcrumbIsVisible, displayBreadcrumbsUpTo, getIndexOfBreadcrumb, } = useAddGameBreadcrumbManager({
        gameStepBreadcrumbs,
        startingIndex: breadcrumbStepOffset,
        includeSelectBreadcrumb: shouldShowSelectBreadcrumb,
        requireAgePrompt,
    });
    const handleBreadcrumbClick = (idx, breadcrumb) => {
        if (breadcrumb.id === 'select') {
            // Invoke onBackToSelect to dismiss current view
            if (onBackToSelect) {
                onBackToSelect(gameKey);
            }
        }
        else if (idx >= breadcrumbStepOffset) {
            setCurrentStepIndex(idx - breadcrumbStepOffset);
        }
    };
    const handleStepChange = (changedStepIndex, stepName) => {
        if (stepName === 'game-connect-status') {
            setBreadcrumbIsVisible(false);
        }
        else {
            setBreadcrumbIsVisible(true);
            displayBreadcrumbsUpTo(stepName);
        }
        if (onStepChange) {
            const analyticsFlowStep = getAnalyticsFlowStep(gameConnectionStatus, changedStepIndex, gameStepBreadcrumbs);
            onStepChange(changedStepIndex, stepName, analyticsFlowStep, gameStepBreadcrumbs.length);
        }
    };
    const gameKey = 'mlb-the-show-23';
    const removeAddGameParams = useRemoveAddGameParams();
    const handleConnectSuccessClick = () => {
        if (onConnectConfirm) {
            onConnectConfirm();
        }
    };
    const getStepIndexFromBreadcrumbId = (relatedBreadcrumbName) => getIndexOfBreadcrumb(relatedBreadcrumbName) - breadcrumbStepOffset;
    const handleConnectFailClick = () => {
        removeAddGameParams();
        setCurrentStepIndex(getStepIndexFromBreadcrumbId('oauth-connect'));
    };
    const handleClose = () => {
        const isSuccessPage = breadcrumbIndex === -1;
        if (onClose) {
            onClose(gameConnectionStatus === 'success', isSuccessPage);
        }
    };
    const { gameServices } = availableGameServices || {};
    const handleSelectService = (serviceKey) => {
        var _a;
        const selectedGameService = gameServices === null || gameServices === void 0 ? void 0 : gameServices.find((gameService) => gameService.gameServiceKey === serviceKey);
        dispatch(setConnectGameServiceAction(selectedGameService !== null && selectedGameService !== void 0 ? selectedGameService : null));
        if ((selectedGameService === null || selectedGameService === void 0 ? void 0 : selectedGameService.platforms.length) === 1) {
            const selectedPlatform = selectedGameService.platforms[0];
            dispatch(setConnectPlatformAction(selectedPlatform));
            // If there is only a single region, select it automatically
            if (((_a = selectedPlatform.regions) === null || _a === void 0 ? void 0 : _a.length) === 1) {
                dispatch(setConnectRegionAction(selectedPlatform.regions[0]));
            }
        }
        const selectedServiceIndex = gameServices === null || gameServices === void 0 ? void 0 : gameServices.findIndex((gameService) => gameService.gameServiceKey === serviceKey);
        if (gameServices && typeof selectedServiceIndex === 'number' && selectedServiceIndex > -1) {
            const selectedService = gameServices[selectedServiceIndex];
            trackEvent({
                type: 'game_linking',
                data: {
                    eventName: 'game linking',
                    label: selectedService.name,
                    moduleName: 'Button',
                    position: selectedServiceIndex,
                },
            });
            setCurrentStepIndex(currentStepIndex + 1);
        }
    };
    const loginMethods = gameServices
        ? gameServices
            .filter((gameService) => gameService.gameServiceKey === 'psn' || gameService.gameServiceKey === 'xboxlive')
            .map((gameService) => ({
            id: gameService.gameServiceKey,
            value: gameService.gameServiceKey,
            label: gameService.name,
            icon: _jsx(LoginServiceIcon, { service: gameService.gameServiceKey, color: "mlb-the-show-23" }),
            paletteName: 'mlb-the-show-23',
        }))
        : [];
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { title: _jsx("span", { "data-ignore": true, children: getGameName(gameKey) }), icon: _jsx(GameIcon, { game: gameKey, showGameColor: true }), subtitle: "Connect your account", paletteName: getGamePalette(gameKey), onClose: handleClose }), _jsx(ModalBreadcrumb, { currentIndex: breadcrumbIndex, breadcrumbs: breadcrumbs, onClick: handleBreadcrumbClick, isVisible: breadcrumbIsVisible }), _jsx(ModalBody, { children: isProviderReady && (_jsxs(ContentCarousel, { name: "MLB23", currentStep: currentStepIndex, onStepChange: handleStepChange, children: [_jsxs(ModalContent, { verticalAlign: "top", stepName: "game-service", children: [_jsx("h3", { id: "add-game-mlb-select-login", children: "Select Login Method" }), loginMethods.length === 0 && _jsx(LoadingAnimationPlaceholder, { title: "Loading Platforms" }), _jsx(Choice, { choices: loginMethods, onSelect: handleSelectService, selectButtonLabel: "Continue", ariaLabelledBy: "add-game-mlb-select-login" }), _jsx("p", { children: "You can connect multiple accounts" })] }), _jsx(AddGameOAuthConnectContent, { stepName: "oauth-connect" }), _jsx(AddGameConnectStatus, { onConfirm: handleConnectSuccessClick, onRetry: handleConnectFailClick, gameKey: gameKey, status: gameConnectionStatus !== null && gameConnectionStatus !== void 0 ? gameConnectionStatus : 'error', successButtonLabel: successButtonLabel, failButtonLabel: failButtonLabel, errorMessage: (fetched === null || fetched === void 0 ? void 0 : fetched.isError) ? fetched.errorMessage : undefined, stepName: "game-connect-status" })] })) }), !isProviderReady && _jsx(AddGameInterstitial, {})] }));
};
export default AddMLBShow23;
