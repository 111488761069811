import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import AddFunds from '@components/Modals/AddFunds/AddFunds';
import ReauthenticateModal from '@components/Modals/AuthenticationModal/Components/Login/ReauthenticateModal';
import WithdrawalModal from '@components/Modals/WithdrawalModal/WithdrawalModal';
import { useLocalAppContext } from '@context/LocalAppContext';
import SiteAlert from '@components/UI/SiteAlert';
import useAppState from '@hooks/API/useAppState';
import trackHotjarEvent from '@components/Analytics/trackHotjarEvent';
import useTrackEvent from '@hooks/useTrackEvent';
import HeaderDesktop from './components/HeaderDesktop';
import CouponModal from './components/CouponModal';
import MobileNavigationHeader from './components/MobileNavigationHeader';
import useShouldHideHeader from './hooks/useShouldHideHeader';
/**
 * Used as the primary navigation for users across repeat.gg
 *
 * @returns The header component
 */
const Header = () => {
    var _a;
    const { siteAlerts } = useAppState();
    const shouldNotRender = useShouldHideHeader();
    const trackEvent = useTrackEvent();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('header'));
    const queryParams = new URLSearchParams(window.location.search);
    const showLoginOnLoad = queryParams.has('login');
    const showSignupOnLoad = queryParams.has('signup');
    const [depositCurrency, setDepositCurrency] = useState(undefined);
    const [showFundsMenu, setShowFundsMenu] = useState(false);
    const [showWithdrawModal, setShowWithdrawModal] = useState(false);
    const [showCouponModal, setShowCouponModal] = useState(false);
    const { openModal } = useLocalAppContext();
    const handleFundsMenuClose = () => {
        setShowFundsMenu(false);
        setDepositCurrency(undefined);
    };
    const handleCloseWithdrawalModal = () => {
        setShowWithdrawModal(false);
    };
    const handleShowUserLoginModal = () => {
        openModal({ type: 'login' });
    };
    const handleShowUserRegisterModal = () => {
        openModal({ type: 'register' });
        trackEvent({
            type: 'initiate_account_registration',
            data: {
                eventName: 'account registration',
                moduleName: 'Button',
                label: 'Sign up',
                position: 0,
            },
        });
    };
    const handleShowCouponModal = () => {
        setShowCouponModal(true);
    };
    const handleShowWithdrawalModal = () => {
        setShowWithdrawModal(true);
    };
    const handleShowFundsModal = () => {
        setShowFundsMenu(true);
        trackHotjarEvent(`Click wallet`);
    };
    // Hide the zendesk web widget
    useEffect(() => {
        var _a, _b;
        if ((_a = window.zE) === null || _a === void 0 ? void 0 : _a.widget) {
            const widget = ((_b = window.zE) === null || _b === void 0 ? void 0 : _b.widget) === 'messenger' ? 'messenger' : 'webWidget';
            window.zE(widget, 'hide');
            window.zE(`${widget}:on`, 'close', function () {
                if (window.zE) {
                    window.zE(widget, 'hide');
                }
            });
        }
    }, [(_a = window.zE) === null || _a === void 0 ? void 0 : _a.widget]);
    // Show login modal if queryParams are detected
    useEffect(() => {
        if (showLoginOnLoad) {
            openModal({ type: 'login' });
        }
        else if (showSignupOnLoad) {
            openModal({ type: 'register' });
        }
    }, []);
    // Do not render for influencer pages
    if (shouldNotRender) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs("div", { "data-testid": "new navigation menu", children: [isMobile ? (_jsx(MobileNavigationHeader, { onShowLoginModal: handleShowUserLoginModal, onShowRegisterModal: handleShowUserRegisterModal, onShowCouponModal: handleShowCouponModal, onShowWithdrawalModal: handleShowWithdrawalModal, onShowFundsModal: handleShowFundsModal })) : (_jsx(HeaderDesktop, { onShowLoginModal: handleShowUserLoginModal, onShowRegisterModal: handleShowUserRegisterModal, onShowCouponModal: handleShowCouponModal, onShowWithdrawalModal: handleShowWithdrawalModal, onShowFundsModal: handleShowFundsModal })), showFundsMenu && _jsx(AddFunds, { onClose: handleFundsMenuClose, selectedCurrency: depositCurrency }), showWithdrawModal && (_jsx(ReauthenticateModal, { handleClose: handleCloseWithdrawalModal, children: _jsx(WithdrawalModal, { open: true, onClose: setShowWithdrawModal }) })), showCouponModal && _jsx(CouponModal, { open: showCouponModal, onClose: () => setShowCouponModal(false) }), siteAlerts === null || siteAlerts === void 0 ? void 0 : siteAlerts.map((alert) => (_jsx(SiteAlert, { children: alert.info }, alert.name)))] }));
};
export default Header;
