import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { styled } from '@mui/system';
import queryString from 'query-string';
import { captureMessage } from '@sentry/react';
import ModalContent from "@components/Modals/components/ModalContent";
import ModalIconBanner from "@components/Modals/components/ModalIconBanner";
import Icon from '../UI/Icon/Icon';
import ConnectGameButton from './ConnectGameButton';
import { useAddGameContext } from './context/AddGameProvider';
import { setIsLoadingAction } from './context/AddGameStepsActions';
import isRegion from './helpers/isRegion';
import getGamePalette from '../../helpers/getGamePalette';
import useOauthReturnPath from '@components/Hooks/useOauthReturnPath';
import getGameServicePalette from '@helpers/getGameServicePalette';
import useTrackEvent from '@hooks/useTrackEvent';
import { useLocalAppContext } from '@context/LocalAppContext';
import { useFeature } from 'flagged';
import getAdobeLocale from '@domain/Analytics/helpers/getAdobeLocale';
const StyledConnectGameButton = styled(ConnectGameButton) `
  align-self: stretch;
`;
const AddGameOAuthConnectContent = ({ title, content, className, stepName, paletteName, gameServiceDisplayName, }) => {
    var _a, _b, _c, _d;
    const { dispatch, isLoading, selectedGame, connectGameRegion, connectGameService, oauthCallbackParams } = useAddGameContext();
    const isAdobeAnalytcsEnabled = useFeature('enable_adobe_analytics');
    const trackEvent = useTrackEvent();
    const { selectedLanguage } = useLocalAppContext();
    const gameServiceName = gameServiceDisplayName !== null && gameServiceDisplayName !== void 0 ? gameServiceDisplayName : connectGameService === null || connectGameService === void 0 ? void 0 : connectGameService.name;
    const isRepeatMobileApp = !!window.ReactNativeWebView;
    const handleClick = () => {
        trackEvent({
            type: 'game_linking',
            data: {
                eventName: 'game linking',
                label: 'Login to Your Account',
                moduleName: 'Text link',
                position: 0,
            },
        });
        dispatch(setIsLoadingAction(true));
        if (!oauthHandover) {
            captureMessage(`Missing oauth route for game: ${selectedGame === null || selectedGame === void 0 ? void 0 : selectedGame.gameKey} service: ${connectGameService === null || connectGameService === void 0 ? void 0 : connectGameService.gameServiceKey}`);
        }
    };
    const returnPath = useOauthReturnPath(oauthCallbackParams, [
        'added_game',
        'add_game_error',
        'add_game_region_code',
        'add_game_service_key',
    ]);
    const gamePaletteName = paletteName !== null && paletteName !== void 0 ? paletteName : getGamePalette((_a = selectedGame === null || selectedGame === void 0 ? void 0 : selectedGame.gameKey) !== null && _a !== void 0 ? _a : 'primary');
    const gameServicePaletteName = getGameServicePalette(connectGameService === null || connectGameService === void 0 ? void 0 : connectGameService.gameServiceKey);
    const adobeLocale = getAdobeLocale(selectedLanguage.code);
    const smcidPlatform = isRepeatMobileApp ? 'app' : 'web';
    const smcid = isAdobeAnalytcsEnabled && (connectGameService === null || connectGameService === void 0 ? void 0 : connectGameService.gameServiceKey) === 'psn'
        ? `${smcidPlatform}:repeat:${adobeLocale}:gamelink`
        : undefined;
    const params = {
        ...oauthCallbackParams,
        return_path: returnPath,
        region: connectGameRegion === null || connectGameRegion === void 0 ? void 0 : connectGameRegion.id,
        game: selectedGame === null || selectedGame === void 0 ? void 0 : selectedGame.gameKey,
        platform: (_c = (_b = connectGameService === null || connectGameService === void 0 ? void 0 : connectGameService.platforms) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.slug,
        smcid,
    };
    const oauthHandover = selectedGame && ((_d = connectGameService === null || connectGameService === void 0 ? void 0 : connectGameService.oauth) === null || _d === void 0 ? void 0 : _d.route) && isRegion(connectGameRegion)
        ? `${connectGameService.oauth.route}?${queryString.stringify(params)}`
        : '';
    return (_jsxs(ModalContent, { className: className, stepName: stepName, align: "center", children: [_jsx(ModalIconBanner, { children: (connectGameService === null || connectGameService === void 0 ? void 0 : connectGameService.gameServiceKey) && (_jsx(Icon, { icon: connectGameService === null || connectGameService === void 0 ? void 0 : connectGameService.gameServiceKey, width: 200, height: 110, color: gameServicePaletteName, strength: "dark" })) }), _jsx("h2", { "data-notranslate": true, children: title || (_jsxs("span", { children: ["Connect to ", _jsx("span", { "data-ignore": true, children: gameServiceName })] })) }), _jsx("section", { children: _jsx("p", { children: content || (_jsxs(_Fragment, { children: ["You'll be redirected to the ", _jsx("span", { "data-ignore": true, children: gameServiceName }), " Login page in order to complete this process"] })) }) }), _jsx(StyledConnectGameButton, { href: oauthHandover, onClick: handleClick, paletteName: gamePaletteName, isLoading: isLoading, children: _jsx("span", { children: "Login to Your Account" }) })] }));
};
export default AddGameOAuthConnectContent;
