import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import getGameName from '@helpers/getGameName';
import getGamePalette from '@helpers/getGamePalette';
import useAddGameBreadcrumbManager from '@hooks/useAddGameBreadcrumbManager';
import ModalBody from "@components/Modals/components/ModalBody";
import ModalBreadcrumb from "@components/Modals/components/ModalBreadcrumb";
import ModalHeader from "@components/Modals/components/ModalHeader";
import GameIcon from '@components/Icons/GameIcon';
import { useAddGameContext } from '../context/AddGameProvider';
import ContentCarousel from '../../UI/ContentCarousel';
import ContentConnectLOL from './ContentConnectLOL';
import { setConnectRegionAction } from '../context/AddGameStepsActions';
import ContentLOLRegions from './ContentLOLRegions';
import AddGameConnectStatus from '../AddGameConnectStatus';
import AddGameInterstitial from '../AddGameInterstitial';
import useSetDefaultGameService from '../hooks/useSetDefaultGameService';
import { useFeature } from 'flagged';
import AddGameOAuthConnectContent from '../AddGameOAuthConnectContent';
import useHandleAddGameEvents from '../hooks/useHandleAddGameEvents';
import getAnalyticsFlowStep from '@domain/Analytics/helpers/getAnalyticsFlowStep';
const AddLOL = ({ onStepChange, onConnectConfirm, shouldShowSelectBreadcrumb = true, onBackToSelect, successButtonLabel = 'Continue', failButtonLabel = 'Retry', onClose, shouldGoToConnectionStatus, }) => {
    const gameKey = 'league_of_legends';
    const isOAuthEnabled = !!useFeature('enable_riot_oauth');
    const gameStepBreadcrumbs = [
        {
            id: 'region',
            component: 'Region',
            analyticsFlowStep: 'select account region',
        },
        {
            id: 'connect-lol',
            component: 'Connect',
            analyticsFlowStep: isOAuthEnabled ? 'connect game' : 'enter game information',
        },
    ];
    const breadcrumbStepOffset = shouldShowSelectBreadcrumb ? 1 : 0;
    const [currentStepIndex, setCurrentStepIndex] = useState(shouldGoToConnectionStatus ? -1 : 0);
    const addGameContext = useAddGameContext();
    const { requireAgePrompt, dispatch, isProviderReady, gameConnectionStatus, fetched, isLoading } = addGameContext;
    // NOTE: Age check for breadcrumb is managed by the hook below.
    const { breadcrumbIndex, breadcrumbs, breadcrumbIsVisible, setBreadcrumbIsVisible, displayBreadcrumbsUpTo } = useAddGameBreadcrumbManager({
        gameStepBreadcrumbs,
        startingIndex: breadcrumbStepOffset,
        includeSelectBreadcrumb: shouldShowSelectBreadcrumb,
        requireAgePrompt,
    });
    useSetDefaultGameService();
    const handleBreadcrumbClick = (idx, breadcrumb) => {
        if (breadcrumb.id === 'select') {
            // Invoke onBackToSelect to dismiss current view
            if (onBackToSelect) {
                onBackToSelect(gameKey);
            }
        }
        else if (idx >= breadcrumbStepOffset) {
            setCurrentStepIndex(idx - breadcrumbStepOffset);
        }
    };
    const handleStepChange = (changedStepIndex, stepName) => {
        if (stepName === 'game-connect-status') {
            setBreadcrumbIsVisible(false);
        }
        else {
            setBreadcrumbIsVisible(true);
            displayBreadcrumbsUpTo(stepName);
        }
        if (onStepChange) {
            const analyticsFlowStep = getAnalyticsFlowStep(gameConnectionStatus, changedStepIndex, gameStepBreadcrumbs);
            onStepChange(changedStepIndex, stepName, analyticsFlowStep, gameStepBreadcrumbs.length);
        }
    };
    const handleContinue = () => {
        setCurrentStepIndex(currentStepIndex + 1);
    };
    const handleConnectSuccessClick = () => {
        if (onConnectConfirm) {
            onConnectConfirm();
        }
    };
    const handleConnectRetryClick = () => {
        setCurrentStepIndex(isOAuthEnabled ? 0 : -2);
    };
    const handleClose = () => {
        const isSuccessPage = breadcrumbIndex === -1;
        if (onClose) {
            onClose(gameConnectionStatus === 'success', isSuccessPage);
        }
    };
    const handleRegionSelect = (region) => {
        dispatch(setConnectRegionAction(region));
        handleContinue();
    };
    const handleLolAccountSubmit = () => {
        setCurrentStepIndex(currentStepIndex + 1);
    };
    useHandleAddGameEvents({
        state: addGameContext,
        onAddGameError: () => setCurrentStepIndex(-1),
        onAddGameSuccess: () => setCurrentStepIndex(-1),
    });
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { title: _jsx("span", { "data-ignore": true, children: getGameName(gameKey) }), icon: _jsx(GameIcon, { game: gameKey, showGameColor: true }), subtitle: "Connect your account", paletteName: getGamePalette(gameKey), onClose: handleClose }), _jsx(ModalBreadcrumb, { currentIndex: breadcrumbIndex, breadcrumbs: breadcrumbs, onClick: handleBreadcrumbClick, isVisible: breadcrumbIsVisible }), _jsxs(ModalBody, { children: [isProviderReady && (_jsxs(ContentCarousel, { name: "league_of_legends", currentStep: currentStepIndex, onStepChange: handleStepChange, children: [_jsx(ContentLOLRegions, { onRegionSelected: handleRegionSelect, stepName: "region" }), isOAuthEnabled ? (_jsx(AddGameOAuthConnectContent, { stepName: "oauth-connect" })) : (_jsx(ContentConnectLOL, { stepName: "connect-lol", onSubmit: handleLolAccountSubmit })), _jsx(AddGameConnectStatus, { onConfirm: handleConnectSuccessClick, onRetry: handleConnectRetryClick, status: gameConnectionStatus || 'error', gameKey: gameKey, successButtonLabel: successButtonLabel, failButtonLabel: failButtonLabel, errorMessage: (fetched === null || fetched === void 0 ? void 0 : fetched.isError) ? fetched.errorMessage : undefined, stepName: "game-connect-status" })] })), !isProviderReady && _jsx(AddGameInterstitial, {})] })] }));
};
export default AddLOL;
